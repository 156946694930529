import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AboutContext } from "../context/AboutContext";
import ReactMarkdown from "react-markdown";
const AboutMe = () => {
  const [data] = useContext(AboutContext);
  const [elements] = useState(data.allDatoCmsQuiJeSui.nodes[0]);
  return (
    <ContentWrapper>
      <h2>{elements.titre}</h2>
      <div className="About">
        <div className="ContentText">
          <ReactMarkdown>{elements.description}</ReactMarkdown>
        </div>
        <div className="Portrait">
          <img src={elements.portrait.url} alt="Photos de Magali Giacobazzi" />
        </div>
      </div>
    </ContentWrapper>
  );
};
const ContentWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
  color: var(--green);

  h2 {
    text-align: center;
    margin: 5rem 0;
  }
  .About {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .ContentText {
      margin-bottom: 5rem;
      width: 50%;

      p {
        font-size: 1rem;
        line-height: 2rem;
      }
    }
  }
  @media screen and (max-width: 876px) {
    .About {
      .ContentText {
        margin-bottom: 5rem;
        width: 90%;
      }
    }
  }
  .Portrait {
    width: 50%;
    position: relative;
    width: 22rem;
    height: 22rem;
    border-radius: 50%;
    margin: 5px 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    overflow: hidden;

    img {
      position: absolute;
      top: -20px;
      background-size: cover;
    }
  }
`;

export default AboutMe;
