import React from "react";
import Layout from "../components/Layout";
import AboutMe from "../components/AboutMe";
import { AboutProvider } from "../context/AboutContext";
import Seo from "../components/seo";
const Quijesuis = () => {
  return (
    <Layout>
      <AboutProvider>
        <Seo title="Magali Sophrologue Toulon A propos de moi" />
        <AboutMe />
      </AboutProvider>
    </Layout>
  );
};

export default Quijesuis;
